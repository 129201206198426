import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/pages/page.scss"
import SEO from "../components/seo";

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO title={currentPage.title} />
        <div className="page">
          <div className="container">
            <h1 className="title">{currentPage.title}</h1>

            <div className="page__content text-style" dangerouslySetInnerHTML={{__html: currentPage.content}}>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
